/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { KycContext } from '../../../context/kyc/KycContext';
import './index.css';

const UploadFile = ({ width }) => {
  const {
    uploadFile,
    load,
    onChange,
    fileInput,
    uploadFileForCac,
  } = useContext(KycContext);

  return (
    <div className="">
      <ToastContainer />

      <div
        className="rounded-2xl flex flex-col p-5 m-5"
        style={{ backgroundColor: '#F5F7F7' }}
      >
        <h4
          className="mt-3 mb-3 font-weight-light"
          style={{ fontSize: '18px', color: '#0C2F52' }}
        >
          Upload KYC
        </h4>
        <p className="mt-3 mb-3 font-weight-light" style={{ fontSize: '13px' }}>
          Please upload either of the listed documents <br />
          on the left window(jpg only)
        </p>
        <div className="row justify-content-center">
          {/* <div className="col-md-8 d-flex p-0"> */}
          <div className={`col-md-${width} d-flex p-0`}>
            <div className="col-md-5 p-0">
              <div
                className="border border-primary ml-3 mt-3 mb-0 mr-3 text-center justify-content-center"
                style={{ width: '300px', backgroundColor: 'white' }}
                onClick={() => {
                  document.getElementById('upload').click();
                }}
              >
                <span
                  className="fas fa-cloud-upload-alt mt-5"
                  style={{ cursor: 'pointer' }}
                />
                <input
                  required
                  type="file"
                  style={{ display: 'none' }}
                  ref={fileInput}
                  onChange={onChange}
                  id="upload"
                  className="form-control mt-2 p-2"
                  multiple
                />

                <div className="mt-3 mb-3 ml-3 mr-3">
                  <h4 className="upload font-weight-light">
                    Drag and Drop your files <br />
                    here or click the here to upload <br />
                    your documents
                  </h4>
                </div>
                <div className="form-group col-12">
                  {load ? (
                    // eslint-disable-next-line react/button-has-type
                    <button className="btn btn-grey w-100 mb-5" disabled={load}>
                      Uploading File...
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-primary btn-sm w-50"
                      onClick={
                        () =>
                          width === '12' ? uploadFileForCac() : uploadFile()
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    >
                      Upload File
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadFile;
