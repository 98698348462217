import React from 'react';
import { Link } from 'react-router-dom';
import OverviewHeader from './OverviewHeader';
import Accounts from './accounts/Accounts';
import RelocationForm from './relocation/RelocationForm';
import { useRelocation } from '../../hooks/relocation/useRelocation';
import PaymentHistoryBody from './payment-history/PaymentHistoryBody';
import { UseAccounts } from '../../hooks/accounts/useAccounts';
import UsageHistoryBody from './usage-history/UsageHistoryBody'; 

const OverviewBody = () => {
  const [{ accounts }] = UseAccounts();
  const {
    show,
    details,
    handleShow,
    handleClose,
    isLoading: loadingForm,
    createRelocationRequest,
  } = useRelocation();
  return (
    <div className='container flex flex-col mb-40 '>
           <OverviewHeader />
           <Accounts />
           <div className='flex mt-4 space-x-3'>
                        <button  className='px-3 text-sm rounded bg-yellow-400 w-32 h-14 hover:bg-gray-500 hover:text-white  transition-all duration-150  ease-in-out'   onClick={() => handleShow(accounts)}>
                            <i class="fas fa-hotel "></i> Relocation
                        </button>

                        <Link to="/subscribe" className='text-gray-600'>
                            <button
                                className='px-3 text-sm rounded bg-green-400 w-32 h-14  hover:bg-gray-500 hover:text-white   transition-all duration-150  ease-in-out'>
                                <i className="fas fa-shopping-cart "></i> Renew
                            </button>

                        </Link>



                    <RelocationForm
                      account={details}
                      handleClose={handleClose}
                      show={show}
                      isLoading={loadingForm}
                      createRelocationRequest={createRelocationRequest}
                    />
                  </div>


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                            <PaymentHistoryBody />
                            <UsageHistoryBody />
                        </div>



    </div>

  );
};

export default OverviewBody;
