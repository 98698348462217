import React from 'react';
import UploadFile from './UploadFile';
import { KycProvider } from '../../../context/kyc/KycContext';
import KycHeader from './KycHeader';
import FilePreview from './FilePreview';

const KycBody = () => {
  return (
    // <div className="" style={{ backgroundColor: '#F5F7F7' }}>
    <div>
      <KycProvider>
        <KycHeader />
        <span>
          <div className="row">
            <div className="col-sm-6 justify-content-center">
              <FilePreview />
            </div>
            <div className="col-sm-6 justify-content-center">
              <div className="border-0 ">
                <UploadFile w="8" />
              </div>
            </div>
          </div>
        </span>
      </KycProvider>
    </div>
  );
};

export default KycBody;
